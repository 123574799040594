import React, { Component} from 'react';
import { FormattedHTMLMessage } from 'react-intl';

class RightDots extends Component {
    constructor (props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.scrollToLocation = this.scrollToLocation.bind(this);
    }

    componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        let mainNavLinks = document.querySelectorAll(".hash");
        let fromTop = window.scrollY;
        mainNavLinks.forEach(link => {
            if ( window.scrollY + window.innerHeight > link.offsetTop + 83 && link.offsetTop > fromTop) {
            // if ( link.offsetTop <= fromTop && link.offsetTop + link.offsetHeight > fromTop ) {
                document.querySelector('.dots-name[href="#'+link.id+'"]').parentNode.classList.add('active')
                document.querySelector('.dots-name[href="#'+link.id+'"]').classList.add('active')
            } else {
                document.querySelector('.dots-name[href="#'+link.id+'"]').parentNode.classList.remove('active')
                document.querySelector('.dots-name[href="#'+link.id+'"]').classList.remove('active')
            }
          });
    }

    scrollToLocation(e) {
        e.preventDefault();
        
        [...document.querySelectorAll('.right-dot')].map((e) => {
            return e.classList.remove('active');
        })
        
        e.currentTarget.parentNode.classList.add('active');
        e.currentTarget.classList.add('active');

        let id = e.currentTarget.getAttribute('href');
        let headerOffset = 0;
        let elPosition = document.querySelector(id).offsetTop;
        let offsetPosition = elPosition - window.innerHeight + document.querySelector(id).offsetHeight - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        }); 
    }

    renderDots() {

        return this.props.items.map( (item, index) => (
                <li className={'right-dot'} key={index} > 
                    <a className="dots-name" href={'#'+item} onClick={this.scrollToLocation}><FormattedHTMLMessage id={item}/></a>
                </li> 
            )
        );
    }

    render () {
        return (
            <ul className='right-dots-container'>
                { this.renderDots() }
            </ul>
        )   
    }
}


export default RightDots;
    

