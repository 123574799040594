import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { NavLink } from "react-router-dom";
import BannerBg1 from '../images/bannerbg01.jpg';
import BannerBg2 from '../images/bannerbg02.jpg';
import BannerBg3 from '../images/bannerbg03.jpg';

class Slider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            slides: [
                {
                    eachSlide: BannerBg1,
                    href: '/about'
                },
                {
                    eachSlide: BannerBg2,
                    href: '/services'
                },
                {
                    eachSlide: BannerBg3,
                    href: '/ftz'
                }
            ],
            autoplay: true,
            active: 0,
            max: 0,
            druation: 5000,
        };

        this.state.max = this.state.slides.length;
        this.intervalBetweenSlides = this.intervalBetweenSlides.bind(this);
        this.handleHover = this.handleHover.bind(this);
    }

    componentDidMount() {
        document.querySelector('.slider').addEventListener('mouseover', this.handleHover)
        document.querySelector('.slider').addEventListener('mouseout', this.handleHover)
        this.interval = setInterval( () => this.intervalBetweenSlides(), this.state.druation); 
    }

    componentWillUnmount() {
        clearInterval( this.interval );
    }

    handleHover() {
        this.setState({
            autoplay: !this.state.autoplay
        });
    }

    intervalBetweenSlides() {
        if (this.state.autoplay === true) {
           
            if (this.state.active === this.state.max - 1) {
                this.setState({
                    active: 0
                });

            } else {
                this.setState({
                    active: this.state.active + 1
                });
            }
        }
    }

    nextOne(){
        if (this.state.active < this.state.max - 1) {
            this.setState({
                active: this.state.active + 1
            });
        } else {
            this.setState({
                active: 0
            });
        }
    }

    prevOne(){
        if (this.state.active > 0) {
            this.setState({
                active: this.state.active - 1
            });
        } else {
            this.setState({
                active: this.state.max - 1
            });
        }
    }

    dots(index, event){
        
        this.setState({
            active: index
        });
    }

    isActive(value){
        if(this.state.active === value){
            return 'active';
        }
    }

    renderSlides() {
        
        return this.state.slides.map( (item, index) => {
            return (
                <div className={'each-slide ' + this.isActive(index)} key={index} style={{backgroundImage:`url(${item.eachSlide})`}}></div>
            )
        });
    }

    renderDots() {

        return this.state.slides.map( (item, index) => (
                <li className={this.isActive(index) + ' dots'} key={index} ref='dots' onClick={ this.dots.bind(this, index) } >
                    <button></button>
                </li> 
            )
        );
    }

    renderContent() {

        return this.state.slides.map( (item, index) => {
            return (
                <div className="slider-container" key={index}>
                    <div className={'text ' + this.isActive(index)} >
                        <FormattedHTMLMessage id={"slogan" + index} />
                        <NavLink exact to={item.href} className="slider-more">
                            <FormattedMessage id="more" />
                        </NavLink>
                    </div>
                </div>
            )
        });
    }

    renderArrows() {
        
        return (
            <div className="control-button">
                <button 
                type='button'
                className='arrows icon-cheveron-outline-left prev' 
                onClick={ this.prevOne.bind(this) } >
                    
                </button>

                <button 
                type='button'
                className='arrows icon-cheveron-outline-right next' 
                onClick={this.nextOne.bind(this)} > 
                </button>
            </div>
        );
    }

    render(){
        return (
            <div className='slider'>
                <div className='wrapper'>
                    { this.renderSlides() }
                </div>

                { this.renderContent() }

                <ul className='dots-container'>
                    { this.renderDots() }
                </ul>
                { this.renderArrows() }
            </div>
        );
    }
};
export default Slider;
