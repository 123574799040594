const en_US = {
  home: "Home",
  about: "About Us",
  services: "Shipping Services",
  ftz: "Introduction to FTZ",
  contact: "Contact",
  more: "More",
  slogan0:
    "<div class='slogan-en'><p>Fulfilling</p><span class='slogan-color'>our customers’</span><p>wishes since 2005</div>",
  slogan1:
    "<div class='slogan-en'><p>You will fall in love with</p><p><span class='slogan-color'>our express shipping</span></p><p>And so will your cargo</p></div>",
  slogan2:
    "<div class='slogan-en'>Our<p><span class='slogan-color'>FTZ warehouse</span></p><p>is your goods’ happy place<p></div>",
  indexAboutArticle:
    "<div class='top-en'><span class='focus'>ASL</span> provides timely and customized logistics and supply chain solutions at the lowest cost to customers across a variety of industries.</div> <div class='bottom'>With 14 years of experience and reliable network of agents and freight forwarders, we guarantee the best quality service and ensure your company’s success.</p></div>",
  indexOcean: "International Ocean Freight",
  indexOceanArticle:
    "Tight cooperation with a group of global shipping companies allows us to provide you with integrated, and most professional inbound and outbound cargo services, as well as 3PL service.",
  indexAir: "International Air Freight",
  indexAirArticle:
    "We also ensure professional, safe and timely air transportation to various destinations thanks to the partnership with reliable agents and airlines based around the world.",
  indexExpress: "International Express Shipping",
  indexExpressArticle:
    "ASL provides reliable and secure international express shipping at the lowest price to locations throughout the world. With ASL your shipment will reach its destination in 2-3 business days.",
  indexBroker: "Customs Broker",
  indexBrokerArticle:
    "ASL offers customs broker services for international express courier and international air/sea service.",
  airPerMonth: "Air Shipment per Month",
  oceanPerMonth: "Ocean Shipment per Month",
  expressPerMonth: "International Express Shipment per Month",
  indexFtz: "WAREHOUSING AND FTZ SERVICES ",
  indexFtzArticle:
    "ASL rented a duty free warehouse in the Taoyuan Free Trade Zone in 2005. Since then we have been providing best quality FTZ services, such as warehousing, logistics, processing, outsourcing, value-add services, customs clearance, business agent service, and multimodal transportation.",
  indxeHeadAddress: "5F No.135,Sec. 4, Bade Road, Taipei, Taiwan",
  indxeBranchAddress: "7F-3, No. 5, Hang-Siang Rd. , Dayuan County, Taiwan",
  aboutIdea: "About ASL",
  aboutIdeaArticle:
    '<p>ASL provides timely and customized logistics and supply chain solutions at the lowest cost to customers across a variety of industries. With 14 years of experience and reliable network of agents and freight forwarders, we guarantee the best quality service and ensure your company’s success.</p> <p>Over the years we obtained world-class expertise in international shipping, ocean and air freight, intermodal transportation, warehousing, logistics, free trade zone services, customs clearance, brokerage, and value-added supply chain management.</p><p>We pride ourselves on being flexible and creative to meet your every unique need, as our ultimate goal is "to serve our clients to their satisfaction". Paying close attention to the client’s requirements along with continual fostering of innovation has always been - and will always be - our guiding principle. </p>',
  aboutAsl: "About ASL",
  aboutUs: "/ About Us",
  history: "History",
  history2005:
    "<li>ASL is set up and obtains Air and Sea forwarder license</li>",
  history2006:
    "<li>ASL sets up a branch office and a warehouse in the Taoyuan Free Trade Zone.</li><li>The company obtains Consumer Electronics Factory Registration Certificate and Air Declaration licens.</li>",
  history2009:
    "<li>ASL enters a joint venture with “Handsome” (an official agent of Australia Asia Line) that specializes in break-bulk and heavy machinery cargoes</li>",
  history2013:
    "<li>The company obtains a Maritime Customs Brokerage license</li>",
  history2014:
    "<li>Rents a warehouse at Taoyuan Free Trade Port</li><li>ASL establishes logistics service factories (Obtains Business Registration Certificate for Auto Parts Factory)</li><li>The company sets up a wine & art warehouse</li>",
  history2016:
    "<li>ASL sets up cooperation with major international express courier provider and a leading e-commerce platform</li>",
  history2018:
    "<li>ASL sets up an international express delivery department in the Taipei Head Office</li>",
  team: "Our Team",
  areatp: "Taipei Head Office",
  areaty: "Taoyuan Branch Office",
  ceo: "CEO",
  pm: "Executive Vice President",
  os: "Overseas Specialist",
  wm: "Warehouse Manager",
  om: "Vice President Of Operations",
  oceanFreight: "<span class='d-none'>International</span> Ocean Freight",
  airFreight: "<span class='d-none'>International</span> Air Freight",
  expressShipping: "<span class='d-none'>International</span> Express Shipping",
  customBroker: "Customs Broker",
  shortceanFreight: "Ocean Freight",
  shortFreight: "Air Freight",
  shortexpressShipping: "Express Shipping",
  shippingService: " / Shipping Service",
  oceanArticle:
    "<p class='focus'>Inbound and outbound cargo services, 3PL, FCL and LCL</p> <p class='normal-en'>Tight cooperation with a group of global shipping companies, allows us to provide you with integrated, and most professional inbound and outbound cargo services, as well as 3PL service.</p><p class='normal-en'>We specialize in global ocean and intermodal transportation, including full container load (FCL) and less container load (LCL), bulk cargoes, special, port-to-port, door-to-door, and one-stop delivery, as well as re-export business service.</p>",
  airArticle:
    "<p class='focus'>Import and export, 3PL, value-added logistics</p><p class='normal-en'>We also ensure professional, safe and timely air transportation to various destinations thanks to the partnership with reliable agents and airlines based around the world. ASL’s airfreight personnel constantly aids its clients in gaining a competitive advantage and improving their transportation network by implementing innovative solutions that allow products to be delivered on time and at the lowest cost.</p><p class='normal-en'>As ASL’s branch office is located in the Free Trade Airport in Taoyuan, our airfreight services range from imports, exports, 3PL service, door-to-door deliveries, to cargo packaging, loading, and value-add logistics services.</p>",
  expressArticle:
    "<p class='focus'>Reliable, secure, competitive rates</p><p class='normal-en'>ASL provides reliable and secure international express shipping at the lowest price to locations throughout the world. With ASL your shipment will reach its destination in 2-3 business days. As we maintain a close partnership with one of the largest international express courier, we can offer you the most competitive rates on the market. Shipments below 30 kg, in particular, can benefit from our low pricing.</p>",
  brokerArticle:
    "<p class='focus'>Professional, dedicated, transparent</p><p class='normal-en'>ASL offers Customs broker services for international express courier and international air/sea service.</p>",
  introductionFtz: "Introduction to FTZ",
  introductionFtzSub: " / Introduction to FTZ",
  taoyuanFtz: "Taoyuan FTZ Warehouse",
  whyChooseUs: "Why Choose Us",
  ftzArticle:
    "<p>A Free Trade Zone is a geographical area within the national territory, in which companies may land, store, handle, manufacture, reconfigure, and re-export their goods while benefiting from a special tax and customs treatment. With our Free Trade Zone services, we will help you optimize the costs of your business.</p>",
  taoyuanList:
    "<li><p><b>Total area of the warehouse</b></p><p>F7; B1: 5,000 m²</p></li><li><p><b>Free Trade Zone independent team</b></p><p>Stacker x3,</p> <p>Self-management license x10,</p> <p>Manpower x16</p><p>Some of our Customers:</p><p> CyberPower, Corsair, Unimicron, SHL Group, Askey, Alltek</p></li>",
  chooseUsArticle:
    "<ul><li>Taiwan is a collection <b>center in the Asia Pacific</b>. Taiwan’s Free Trade Zones are strategically located proving easy access to mainland China, Hong Kong, Japan, Korea, and other Southeast Asian markets, which your products can reach in less than 4 hours by air.</li><li>Taiwan’s developed high-tech industry and strong manufacturing capabilities give you the opportunity of a <b>high-quality assembly and production</b> of your goods.</li><li>And we not only provide the best quality service but also a service at a reasonable price. As the costs of manpower are significantly lower compared to the other Asian countries, such as Singapore or Hong Kong, we can offer you a <b>competitive quotation</b>.</li><li>With the rising importance of cyber security, Taiwan’s <b>effective data protection</b> allows eliminating the danger of hacker attacks within the supply chain.</li><li><b>Developed B2B</b> infrastructure in Taiwan enables not only an integrated business, logistics, cash, and information flow, but also fast manufacturing, ordering, transport, and sales process.</li></ul>",
  contactUs: "Contact Us",
  headOffice: "ASL Taipei Head Office",
  headAddress: "〒 5F-1 No.135, Sec. 4, Bade Road, Taipei, Taiwan",
  headTel: "Tel +886-2-2747-0123",
  headFax: "Fax +886-2-2747-2333",
  headMail: "E-mail: <span class='hover-color'>cola@asl-tpe.com</span>",
  branchOffice: "ASL Taoyuan Airport <br />FTZ Branch Office",
  branchAddress: "〒 7F-3, No. 5, Hang-Siang Rd., Dayuan County, Taiwan",
  branchTel: "TEL +886-3-399-5845",
  branchFax: "FAX +886-3-399-5849",
  branchMail: "E-mail: <span class='hover-color'>cola@asl-tpe.com</span>",
  null: " ",
}
export default en_US
