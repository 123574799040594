import React , { Component }from 'react';
import {FormattedHTMLMessage} from 'react-intl';

class ArticleTitle extends Component {

    render() {
        return (
            <div className="title">
                <span className="main">
                    <FormattedHTMLMessage id={this.props.title}/>
                </span>
                <span className="sub">
                    <FormattedHTMLMessage id={this.props.subtitle}/>
                </span>
            </div>
        );
    }
}

export default ArticleTitle;
    

