import React, { Component, Fragment } from "react"
import { FormattedHTMLMessage } from "react-intl"
import { connect } from "react-redux"
import Banner from "../componet/banner"
import RightDots from "../componet/rightDots"
import ArticleTitle from "../componet/articleTitle"
import ArticleContent from "../componet/articleContent"

class Ftz extends Component {
  renderPartner(partners) {
    return
  }

  render() {
    const BannerEnSettings = {
      title: "ftz",
      items: ["introductionFtz", "taoyuanFtz", "partner", "whyChooseUs"],
    }

    const BannerSettings = {
      title: "ftz",
      items: ["introductionFtz", "taoyuanFtz", "partner"],
    }

    const Partner = {
      images: [
        require("../images/ftz/plogo01.jpg"),
        require("../images/ftz/plogo02.jpg"),
        require("../images/ftz/plogo03.jpg"),
        require("../images/ftz/plogo04.jpg"),
        require("../images/ftz/plogo05.jpg"),
        require("../images/ftz/plogo06.jpg"),
      ],
    }

    const ArticleEn = {
      page: "ftz",
      article: "ftzArticle",
      img: require("../images/ftz/Estep.jpg"),
      backgroundType: "",
      reverse: true,
    }

    const ChooseUs = {
      page: "ftz",
      article: "chooseUsArticle",
      img: require("../images/ftz/choose.jpg"),
      backgroundType: "",
      reverse: false,
    }

    const ArticleTw = {
      page: "ftz",
      article: "ftzArticle",
      img: require("../images/ftz/c_step.png"),
      backgroundType: "",
      reverse: true,
    }

    if (this.props.locale === "en") {
      return (
        <Fragment>
          <Banner {...BannerEnSettings} />
          <RightDots {...BannerEnSettings} />
          <div id="introductionFtz" className="article-en hash">
            <ArticleTitle
              title="introductionFtz"
              subtitle="introductionFtzSub"
            />
            <ArticleContent {...ArticleEn} />
          </div>
          <div className="taoyuan-ftz-title">
            <ArticleTitle title="taoyuanFtz" subtitle="introductionFtzSub" />
          </div>
          <div id="taoyuanFtz" className="hash">
            <div className="taoyuanFtz-content">
              <div className="img360">
                <iframe
                  title="img360"
                  height="100%"
                  width="100%"
                  allowFullScreen={true}
                  src="https://momento360.com/e/uc/5ff7aeacd5024c38bc9355f0dde72349?utm_campaign=embed&utm_source=other&utm_medium=other"
                ></iframe>
              </div>
              <ul className="taoyuanFtz-list">
                <FormattedHTMLMessage id="taoyuanList" />
              </ul>
            </div>
          </div>

          <div id="whyChooseUs" className="article-en hash">
            <ArticleTitle title="whyChooseUs" subtitle="introductionFtzSub" />
            <div className="chooseUs-title">
              Here are five reasons to choose our service in Taiwan’s FTZ.
            </div>
            <ArticleContent {...ChooseUs} />
          </div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Banner {...BannerSettings} />
          <RightDots {...BannerSettings} />
          <div id="introductionFtz" className="article-en hash">
            <ArticleTitle
              title="introductionFtz"
              subtitle="introductionFtzSub"
            />
            <ArticleContent {...ArticleTw} />
          </div>
          <div className="taoyuan-ftz-title">
            <ArticleTitle title="taoyuanFtz" subtitle="introductionFtzSub" />
          </div>
          <div id="taoyuanFtz" className="hash">
            <div className="taoyuanFtz-content">
              <div className="img360">
                <iframe
                  title="img360"
                  height="100%"
                  width="100%"
                  allowFullScreen={true}
                  src="https://momento360.com/e/uc/5ff7aeacd5024c38bc9355f0dde72349?utm_campaign=embed&utm_source=other&utm_medium=other"
                ></iframe>
              </div>
              <ul className="taoyuanFtz-list">
                <FormattedHTMLMessage id="taoyuanList" />
              </ul>
            </div>
          </div>
        </Fragment>
      )
    }
  }
}

export default connect(({ lang: { locale } }) => ({
  locale,
}))(Ftz)
