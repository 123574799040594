import React , { Component }from 'react';
import {FormattedHTMLMessage} from 'react-intl';

class ArticleContent extends Component {
    
    render() {
        return (
            <div className={this.props.reverse ? "article-content reverse "+ this.props.page : "article-content "+ this.props.page}>
                <div className={'article-content-text'}><FormattedHTMLMessage id={this.props.article} /></div>
                <div className="article-content-img">
                    <img src={this.props.img} alt="img"/>
                    <div className={this.props.backgroundType}></div>
                </div>
            </div>
        );
    }
}

export default ArticleContent;
    

