import React, {Component} from 'react';
import { connect } from 'react-redux';
import { changeLang } from '../actions';

class Lang extends Component {
    constructor (props) {
        super(props);
        this.setLanguage = this.setLanguage.bind(this);
    }

    setLanguage(lang, e) {
        this.props.changeLang(lang);
        
        [...document.querySelectorAll('.lang-link')].map((e) => {
            return e.classList.remove('active');
        })
        
        e.currentTarget.classList.add('active');
        
        document.querySelector('.mobile-menu').classList.toggle('active');
        document.querySelector('#icon-menu').classList.toggle('icon-close');
        document.querySelector('#icon-menu').classList.toggle('icon-menu');
    }
    
    render() {
        return (
            <div className="lang-list" >
                <button className="lang-link active" onClick={(e) => this.setLanguage('en', e)}>EN</button>
                <button className="lang-link" onClick={(e) => this.setLanguage('zh', e)}>繁中</button>
                <button className="lang-link">
                    <a className="icon" target="_blank" href="https://www.facebook.com/ASLTaiwan/" rel="noopener noreferrer">
                        <span className="icon-fb"></span>
                    </a>
                </button>
            </div>
        )
    }
}

export default connect(null, { changeLang })(Lang)