const zh_TW = {
  home: "首頁",
  about: "公司簡介",
  services: "運輸服務",
  ftz: "自由貿易港區",
  contact: "聯絡我們",
  more: "了解更多",
  slogan0:
    "<div class='slogan'><p><span class='slogan-color'>裕</span>民足國</p><p><span class='slogan-color'>隆</span>情盛意</p><p><span class='slogan-color'>行</span>之有效</p>",
  slogan1: "<div class='slogan'>精選快遞，翱翔各地</p>",
  slogan2:
    "<div class='slogan'>自由貿易港區<span class='sm-slogan'>為物流規劃加分</span></div>",
  indexAboutArticle:
    "<div class='top'><p><span class='focus'>裕隆行國際運通</span> 股份有限公司於2005年成立</p><p>以新起之秀的姿態投入國際海、空運輸及物流服務之</p><p>行列儼然已成為一國際性專業之海、空運輸業者。</p></div><p class='bottom'>於2006年成立自由貿易區 (Free Trade Zone)分公司<br />整合境內加工、零關稅及快速通關的概念，進一步提升對客戶的服務。</p>",
  indexOcean: "國際海運",
  indexOceanArticle:
    "裕隆行與多個全球航商有密切聯繫，並備專業、親切及有效率的服務人員，提供台灣進、出口及三角貿易業務。",
  indexAir: "國際空運",
  indexAirArticle:
    "裕隆行的航空運輸有最專業的服務人員最快速與需求，並以快速、便利與可靠的需求上，提供最便利與可靠的服務。",
  indexExpress: "國際快遞",
  indexExpressArticle:
    "裕隆行與多個全球航商有密切聯繫，並備專業、親切及有效率的服務人員， 提供台灣進、出口及三角貿易業務。",
  indexBroker: "相關業務",
  indexBrokerArticle:
    "受進出口商之委託，代理進口商貨出口領交給進口商的一種服務。",
  airPerMonth: "空運進出口量/月",
  oceanPerMonth: "海運進出口量/月 ",
  expressPerMonth: "國際快遞出口量/月 ",
  indexFtz: "自由貿易",
  indexFtzArticle:
    "裕隆行在這個區域內承租空間從事貿易、倉儲、物流、貨櫃（物）之集散、轉口、轉運、承攬運送、報關服務、組裝、重整、包裝、修理、裝配、加工、製造、檢驗、測試、展覽或技術服務之事業。 ",
  indxeHeadAddress: "台北市八德路四段135號5樓-1",
  indxeBranchAddress: "桃園縣大園鄉竹圍村航翔路5號7樓之3",
  aboutIdea: "經營理念",
  aboutIdeaArticle:
    '<p class="focus"><b>唯有裕隆行，用心串全球</b>的經營理念透過彈性的組織運作，在競爭激烈的市場上奠定良好的競爭利基。</p><p class="normal">裕隆行國際運通股份有限公司由專業的經營團隊領導，抱持著"唯有裕隆行，用心串全球"的經營理念，所以我們具有開闊的國際觀，于全世界八十餘國建立近200個代理網絡，並且持續增加中，透過彈性的組織運作，在競爭激烈的市場上奠定良好的競爭利基。在服務方面，一貫秉持著以客為尊的服務，深入了解客戶各種的不同需求，致力發展運輸、報關、倉儲與加工的一貫整合型物流服務，讓客戶透過裕隆行的服務，能夠快速回應市場變化和顧客需求，同時將經營成本與風險降至最低，進而發揮整體經營的最大效益。</p>',
  aboutAsl: "關於裕隆行",
  aboutAslArticle:
    '<p class="normal">裕隆行國際運通股份有限公司於2005年成立，以新起之秀的姿態投入國際海、空運輸及物流服務之行列，儼然已成為一國際性專業之海、空運輸業者；成立至今除已具備了完善的海、空運架構外，更於2006年成立自由貿易區 (Free Trade Zone)分公司，希望藉由整合境內加工、零關稅及快速通關的概念，進一步提升對客戶的服務。</p><p class="normal">公司成立至今秉持著專業、熱忱與堅持不斷自我提升的理念，獲得許多國際性大廠、大型航商及代理的肯定與支持，並肯定裕隆行入主自由貿易港區的行動與至今的優異表現。但裕隆行絕不因此而鬆懈，為因應客戶的需求，將配合自由貿易區分公司的成立，另設立物流部門，提升目前現有的行銷服務網，作到多元化、精緻化及更有效率的服務。</p>',
  aboutUs: "/ 關於裕隆行",
  history: "歷史沿革",
  history2005: "<li>4月成立 | 5月空運承攬許可證 | 11月海運承攬許可證</li>",
  history2006:
    "<li>取得消費性電子工廠登記證(桃園)同年也取得空運報關執照</li><li>5月成立桃園自由貿易港區分公司</li><li>7月第一間獲得籌設於自由貿易港區許可證的海空運承攬業者</li>",
  history2007: "<li>通過台北市電腦商業同業公會TCA補助款</li>",
  history2010: "<li>1月成立基隆海運自由貿易港物流部</li>",
  history2011:
    "<li>通過經濟部工業局中小企業即時技術輔導計畫</li><li>5月取得基隆港自由貿易港西十四號碼頭倉庫營運許可並取得海運自由貿易港營運許可執</li>",
  history2012:
    "<li>桃園取得醫療器材製造之工廠登記證</li><li>7月成立基隆自由貿易港區分公司</li>",
  history2013:
    "<li>通過經濟部商業司產業運籌服務化輔導</li><li>4月取得基隆海運報關執照</li>",
  history2014:
    "<li>承租桃園自貿港7-9倉庫並設置紅酒、藝術品倉庫、取得汽車零配件工廠營業登記證、召開AEO啟始會議、通過經濟部商業司產業運籌服務化輔導期中報告</li>",
  history2016: "<li>桃園自由貿易港區增設國際快遞以及電商轉運部門</li>",
  history2018: "<li>台北辦公司增設國際快遞部門</li>",
  team: "組織團隊",
  areatp: "Taipei Head Office",
  areaty: "Taoyuan Branch Office",
  ceo: "總經理",
  pm: "執行副總",
  os: "國外部專員",
  wm: "倉庫經理",
  om: "營運副總",
  oceanFreight: "國際海運",
  airFreight: "國際空運",
  expressShipping: "國際快遞",
  customBroker: "報關業務",
  shippingService: " / 運輸服務",
  oceanArticle:
    "<p class='normal'>裕隆行與多個全球航商有密切聯繫，並具備專業、親切及有效率的服務人員， 提供台灣進、出口及三角貿易業務。服務範圍涵蓋全球，提供包含整櫃（FCL）、併櫃（LCL）、特殊櫃及特殊貨品之散裝運送，不論單純的port to port、轉口或 door to door 一條龍服務，皆以最專業的服務與最合理的價格，將您的貨物安全運達指定地點。</p>",
  airArticle:
    "<p class='normal'>裕隆行的航空運輸有最專業的服務人員以最快速的回覆速度，回覆所有客戶有關於國際空運的報價與需求，並以快速、便利與可靠著稱，在客戶對於貨物快速運輸與準確的需求上，提供最便利與可靠的服務。</p><p class='normal'>服務項目包含三角、進出口國際運輸，提供door to door的一貫服務；此外，亦於桃園中正國際機場設立自由貿易港的分公司，提供貨物分裝併裝及加值物流服務處理。於全球各主要據點皆有可靠的代理與航空公司配合，提供最專業的服務與最合理的價格將您的貨物於最短的時間內，安全運達指定地點。</p>",
  expressArticle:
    "<p class='normal'>裕隆行能提供優質的國際快遞服務以及最優惠的費用，能使您的貨物在短短的2-3天內安全抵達目的地，若您經常需要寄送樣品不用懷疑，請馬上聯繫我們!!!</p>",
  brokerArticle:
    "<p class='normal'>受進出口商之委託，代理進口商貨出口商到海關辦理進出口通關手續，待完成通關手續後，將進口貨物提領交給進口商(或將出口貨物完成裝運手續)的一種服務。</p><p class='normal'>為進出口商向海關辦理進出口相關業務 / 報關行多隸屬貨運承攬公司的報關部。</p>",
  introductionFtz: "自由貿易港區",
  introductionFtzSub: " / 自由貿易港區",
  taoyuanFtz: "桃園自由貿易港區倉庫",
  ftzArticle:
    "<p>是指特定區域，於國際航空站、國際港口管制區域內；或毗鄰地區劃設管制範圍；或與國際航空站、國際港口管制區域間，能運用科技設施進行周延之貨況追蹤系統，並經政府核定設置管制區域進行國內外商務活動之工業區、加工出口區、科學工業園區或其他區域，成為一種「境內關外」之特區。</p><p>區域內對於港區內之營運採低度行政管制及高度自主管理，並排除港區內進行商務活動之各種障礙，以加速貨物進出港區之流通，推動貿易自由化及國際化，使成為主導國際間貿易之樞紐及集散、交易中心。</p>",
  taoyuanList:
    "<li><p><b>桃園自由貿易港區倉庫</b></p><p>桃園・遠雄自由貿易港7F & B1</p><p>合計約1600坪</p></li><li><p><b>自由貿易港自主車隊證照：</b></p><p>堆高機x3、自主管理執照x10、人力x16</p></li>",
  contactUs: "聯絡我們",
  headOffice: "裕隆行國際運通股份有限公司",
  headAddress: "〒 105 台北市松山區八德路四段135號5樓-1",
  headTel: "Tel +886-2-2747-0123",
  headFax: "Fax +886-2-2747-2333",
  headMail: "E-mail: <span class='hover-color'>cola@asl-tpe.com</span>",
  branchOffice: "自由貿易港分公司",
  branchAddress: "〒 337 桃園市大園區航翔路5號7樓之3",
  branchTel: "TEL +886-3-399-5845",
  branchFax: "FAX +886-3-399-5849",
  branchMail: "E-mail: <span class='hover-color'>cola@asl-tpe.com</span>",
  null: " ",
}
export default zh_TW
