

import zhMessages from "../lang/zh";
import enMessages from "../lang/en";

const mapLang = (lang) => {
    switch (lang) {
        case "zh":
            return { locale: lang, messages: zhMessages };
        case "en":
            return { locale: lang, messages: enMessages };
        default:
            return { locale: lang, messages: zhMessages };
    }
}

export default (state = {locale:'en', messages: enMessages}, action) => {
    switch (action.type) {
        case 'CHANGE_LANG':
          return { ...mapLang(action.lang) }
        default:
          return state;
      }
   }