import React , { Component, Fragment} from 'react';
import Banner from '../componet/banner';
import RightDots from '../componet/rightDots';
import ArticleTitle from '../componet/articleTitle';
import ArticleContent from '../componet/articleContent';

class Service extends Component {

    render() {
        const BannerSettings = {
            title : "services",
            items: ["oceanFreight", "airFreight", "expressShipping", "customBroker"],
        }

        const serviceArticle = {
            article1:{
                page: 'services',
                article:"oceanArticle", 
                img:require('../images/service/service01.jpg'),
                backgroundType: 'bottom',
                reverse: true
            },
            article2:{
                page: 'services',
                article:"airArticle", 
                img:require('../images/service/service02.jpg'),
                backgroundType: 'bottom',
                reverse: false
            },
            article3:{
                page: 'services',
                article:"expressArticle", 
                img:require('../images/service/service03.jpg'),
                backgroundType: 'bottom',
                reverse: true
            },
            article4:{
                page: 'services',
                article:"brokerArticle", 
                img:require('../images/service/service04.jpg'),
                backgroundType: 'bottom',
                reverse: false
            },
        }
    
        return (
            <Fragment>
                <Banner {...BannerSettings} />
                <RightDots {...BannerSettings}/>
                <div id="oceanFreight" className="article hash">
                    <ArticleTitle title="oceanFreight" subtitle="shippingService"/>
                    <ArticleContent {...serviceArticle.article1}/>
                </div>

                <div id="airFreight" className="article hash">
                    <ArticleTitle title="airFreight" subtitle="shippingService"/>
                    <ArticleContent {...serviceArticle.article2}/>
                </div>

                <div id="expressShipping" className="article hash">
                    <ArticleTitle title="expressShipping" subtitle="shippingService"/>
                    <ArticleContent {...serviceArticle.article3}/>
                </div>

                <div id="customBroker" className="article hash">
                    <ArticleTitle title="customBroker" subtitle="shippingService"/>
                    <ArticleContent {...serviceArticle.article4}/>
                </div>
            </Fragment>
        );    
        
    }
}

export default Service;

    

