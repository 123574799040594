import React from 'react';
import { connect } from 'react-redux';
import './css/App.scss';
import Header from './layout/header';
import Footer from './layout/footer';
import Main from './layout/main';
import About from './layout/about';
import Service from './layout/service';
import Ftz from './layout/ftz';
import Contact from './layout/contact';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ScrollToTop from "./componet/scrollToTop";
import { addLocaleData, IntlProvider } from "react-intl";
import en from 'react-intl/locale-data/en';
import zh from "react-intl/locale-data/zh";
addLocaleData([...en, ...zh]);
  

class App extends React.Component {

  render() {
		const IndexPage = () => <Main />;
		const AboutPage = () => <About />;
		const ServicePage = () => <Service />;
		const ContactPage = () => <Contact />;
		const FtzPage = () => <Ftz />;

    const MenuSettings = {
			logoHref:'/',
			top: true,
			menuItems: ["home", "about", "services", "ftz", "contact"]
	  }
	  
    return (
		<IntlProvider locale={this.props.locale} messages={this.props.messages}>
			<div className="App">
				<Router>
					<Header items={MenuSettings.menuItems}/>
					<Route exact path="/" component={IndexPage} />
					<Route path="/about/" component={AboutPage} />
					<Route path="/services/" component={ServicePage} />
					<Route path="/ftz/" component={FtzPage} />
					<Route path="/contact/" component={ContactPage} />
					<ScrollToTop>
						<Footer items={MenuSettings.menuItems}/>
					</ScrollToTop>
				</Router>
			</div>
		</IntlProvider>
		
    );
  }
}

export default connect(({lang:{locale, messages}}) => ({
  locale, messages
}))(App);