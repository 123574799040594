import React, { Component, Fragment} from 'react';
import Lang from '../componet/lang';
import Logo from '../componet/logo';
import Menu from '../componet/menu';

class Header extends Component {
    constructor (props) {
        super(props);
        this.state = {};
        this.handleScroll = this.handleScroll.bind(this);
        this.headerSetTop = this.headerSetTop.bind(this);
    }

    componentDidMount() {
        const el = document.querySelector('header');
		this.setState({top: el.offsetTop, height: el.offsetHeight});
		window.addEventListener('scroll', this.handleScroll);
    }
    
    componentDidUpdate() {
        const el = document.querySelector('header');
        
        if(this.state.scroll > 40) {
            el.classList.add('fix-header');
        } else {
            el.classList.remove('fix-header');
        }
    }
    
    headerSetTop(e) {
        return window.location.pathname !==  '/' ? 'top' : '' ;
    }

    handleScroll() {
        this.setState({scroll: window.scrollY});
    }

    render() {
        return (
            <Fragment>
                <header className={this.headerSetTop()}>
                    <Logo />                        
                    <Menu items={this.props.items}>
                        <Lang />
                    </ Menu>
                </header>
            </Fragment>
        )
    }
}

export default Header;
