import React, { Component, Fragment} from 'react';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

class Navitem extends Component {
    constructor (props) {
        super(props);
        this.scrollToLocation = this.scrollToLocation.bind(this);
    }

    scrollToLocation(e) {
        e.preventDefault();

        document.querySelector('.banner-toggle-menu .banner-nav').classList.remove('active');

        [...document.querySelectorAll('.banner-nav-link')].map((e) => {
            return e.classList.remove('active');
        })

        e.currentTarget.childNodes[0].classList.add('active');
        let id             = e.currentTarget.getAttribute('data-href');
        let headerOffset   = 150;
        let elPosition     = document.querySelector(id).offsetTop;
        let offsetPosition = elPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        }); 
    }

    render() {
        return (
            <li className="banner-nav-item icon-cheveron-right" onClick={this.scrollToLocation} data-href={'#'+this.props.item}>
                <a className="banner-nav-link" href={'#'+this.props.item} ><FormattedHTMLMessage id={this.props.item}/> </a>
            </li>    
        )
    }
}

class NavList extends Component {

    render() {
        let items = this.props.items.map((item, index) => {
            return <Navitem key={index} item={item}/>
        })

        return (
            <nav className="banner-nav">
                <ul className="banner-nav-list" items={this.props}>
                    {items}
                </ul>
            </nav>
        )
    }
}

class Banner extends Component {
    constructor (props) {
        super(props);
        this.state = {};
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        const el = document.querySelector('.banner-toggle-menu');
		this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
        document.querySelector('.cheveronicon').addEventListener('click', (e) => {
            e.target.classList.toggle('icon-cheveron-up');
            e.target.classList.toggle('icon-cheveron-down');
        });
    }
    
    componentDidUpdate() {
        const el = document.querySelector('.banner-toggle-menu');
        if(this.state.BannerScroll > 350 && !document.querySelector('.mobile-menu').classList.contains('active')) {
            el.classList.add('active');
        } else {
            document.querySelector('.banner-toggle-menu .banner-nav').classList.remove('active');
            el.classList.remove('active');
        }
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        }
    }

    handleScroll() {
        this.setState({BannerScroll: window.scrollY});
    }

    showToggleMenu () {
        document.querySelector('.banner-toggle-menu .banner-nav').classList.toggle('active');
    }

    checkIsContact() {
        if(window.location.pathname === '/contact') {
            return {display: 'none'}
        }
    }
    
    render() {
        return (
            <Fragment>
                <div className="banner">
                    <div className="banner-title"><FormattedMessage id={this.props.title} /></div>
                    <NavList items={this.props.items}></NavList>
                </div>
                <div className="banner-toggle-menu" style={this.checkIsContact()}>
                    <div className="banner-title" onClick={this.showToggleMenu.bind(this)} >
                        <FormattedMessage id={this.props.title} />
                        <span className="cheveronicon icon-cheveron-down"></span>
                    </div>
                    <NavList items={this.props.items}></NavList>
                </div>
            </Fragment>
        )
    }
}

export default Banner;