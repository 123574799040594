import React, { Component } from 'react';
import { Link } from "react-router-dom";
import logoImg from '../images/logo.png'

class Logo extends Component {

    render() {
        return (
            <div className="logo">
                <Link to="/" className="logo-link">
                    <figure className="logo-img">
                        <img  src={logoImg} alt="logo"/>
                    </figure>
                </Link>
            </div>
        )
    }
}

export default Logo