import React, { Component, Fragment} from 'react';
import { NavLink } from "react-router-dom";
import {FormattedMessage} from 'react-intl';

class Menu extends Component {

    componentDidMount() {
        let toggleMenu = document.querySelector('.icon-menu');
        toggleMenu.addEventListener('click', this.showMenu)
        toggleMenu.addEventListener('touchstart', this.showMenu)
    }

    showMenu(e) {
        e.preventDefault();
        document.querySelector('#icon-menu').classList.toggle('icon-close');
        document.querySelector('#icon-menu').classList.toggle('icon-menu');
        document.querySelector('.mobile-menu').classList.toggle('active');

        if(document.querySelector('.mobile-menu').classList.contains('active')) {
            if(typeof(document.querySelector('.banner-toggle-menu')) &&  document.querySelector('.banner-toggle-menu') != null){
                document.querySelector('.banner-toggle-menu').classList.remove('active')
            }
        } else {
            if(typeof(document.querySelector('.banner-toggle-menu')) &&  document.querySelector('.banner-toggle-menu') != null){
                if(window.scrollY > 350) {
                    document.querySelector('.banner-toggle-menu').classList.add('active')
                }
            }
        }
        
        
    }

    colseMenu() {
        document.querySelector('#icon-menu').classList.toggle('icon-close');
        document.querySelector('#icon-menu').classList.toggle('icon-menu');
        document.querySelector('.mobile-menu').classList.toggle('active');
        if(typeof(document.querySelector('.banner-toggle-menu')) &&  document.querySelector('.banner-toggle-menu') != null){
            document.querySelector('.banner-toggle-menu').classList.add('active')
        }
    }

    renderMobileMenu() {
        let items = this.props.items.map((item, index) => {
            let href = item === 'home' ? '/' : '/' + item;
            return (
                <li className="menu-item" key={index}>
                    <NavLink exact to={href} className="menu-link" onClick={this.colseMenu} activeStyle={{color: '#ff6c2b'}} ><FormattedMessage id={item}/></NavLink>
                </li>
            )
        })
        
        return (
            <ul className="menu-list">
                {items}
            </ul>
        )

    }

    render() {
        let items = this.props.items.map((item, index) => {
            let href = item === 'home' ? '/' : '/' + item;
            return (
                <li className="menu-item" key={index}>
                    <NavLink exact to={href} className="menu-link" activeStyle={{color: '#ff6c2b'}} ><FormattedMessage id={item}/></NavLink>
                </li>
            )
        })

        return (
            <Fragment>
                <div className="menu">
                    {this.props.children}
                    <ul className="menu-list">
                        {items}
                    </ul>
                </div>
                <div className="mobile-menu">
                    {this.props.children}
                    {this.renderMobileMenu()}
                </div>

                <div className="toggle-menu" >
                    <span id="icon-menu" className="icon-menu"></span>
                </div>
            </Fragment>
        );
    }
}

export default Menu;