import React, { Component, Fragment } from "react"
import { FormattedHTMLMessage } from "react-intl"
import { connect } from "react-redux"
import { NavLink } from "react-router-dom"
import Slider from "../componet/slider"
import CountUp from "react-countup"
import IndexAboutImg from "../images/index_about.png"

class IndexAbout extends Component {
  render() {
    return (
      <div className="index-about">
        <div className="index-about-content">
          <div className="index-about-text">
            <div className="index-about-text">
              <FormattedHTMLMessage id="indexAboutArticle" />
            </div>
            <div className="index-about-text-more">
              <NavLink
                exact
                to="/about"
                className="index-about-button"
                activeStyle={{ color: "#ff6c2b" }}
              >
                + <FormattedHTMLMessage id="more" />{" "}
              </NavLink>
            </div>
          </div>

          <figure className="index-about-img">
            <img src={IndexAboutImg} alt="" />
          </figure>
        </div>
      </div>
    )
  }
}

class IndexService extends Component {
  render() {
    return (
      <div className="index-service">
        <div className="index-service-item">
          <div className="icon">
            <span className="icon-icon01"></span>
          </div>
          <div className="index-service-content">
            <div className="index-service-name">
              <FormattedHTMLMessage id="services" />
            </div>
            <div className="index-service-title">
              <NavLink exact to="/services">
                <FormattedHTMLMessage id="indexOcean" />
              </NavLink>
            </div>
            <div className="index-service-article">
              <FormattedHTMLMessage id="indexOceanArticle" />
            </div>
          </div>
        </div>
        <div className="index-service-item">
          <div className="icon">
            <span className="icon-icon02"></span>
          </div>
          <div className="index-service-content">
            <div className="index-service-name">
              <FormattedHTMLMessage id="services" />
            </div>
            <div className="index-service-title">
              <NavLink exact to="/services">
                <FormattedHTMLMessage id="indexAir" />
              </NavLink>
            </div>
            <div className="index-service-article">
              <FormattedHTMLMessage id="indexAirArticle" />
            </div>
          </div>
        </div>
        <div className="index-service-item">
          <div className="icon">
            <span className="icon-icon03"></span>
          </div>
          <div className="index-service-content">
            <div className="index-service-name">
              <FormattedHTMLMessage id="services" />
            </div>
            <div className="index-service-title">
              <NavLink exact to="/services">
                <FormattedHTMLMessage id="indexExpress" />
              </NavLink>
            </div>
            <div className="index-service-article">
              <FormattedHTMLMessage id="indexExpressArticle" />
            </div>
          </div>
        </div>
        <div className="index-service-item">
          <div className="icon ">
            <span className="icon-icon04"></span>
          </div>
          <div className="index-service-content">
            <div className="index-service-name">
              <FormattedHTMLMessage id="services" />
            </div>
            <div className="index-service-title">
              <NavLink exact to="/services">
                <FormattedHTMLMessage id="indexBroker" />
              </NavLink>
            </div>
            <div className="index-service-article">
              <FormattedHTMLMessage id="indexBrokerArticle" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class IndexNumbers extends Component {
  constructor(props) {
    super(props)
    this.state = { toRun: false }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    this.setState({ count: false })
    window.addEventListener("scroll", this.handleScroll)

    let numberOffsetHeight = document
      .querySelector(".index-number")
      .getBoundingClientRect().top
    let numberHeight = document
      .querySelector(".index-number")
      .getBoundingClientRect().height

    this.setState({ startHeight: numberOffsetHeight - numberHeight })
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }

  handleScroll() {
    this.setState({ scroll: window.scrollY })
    if (this.state.scroll >= this.state.startHeight) {
      this.setState({ toRun: true })
    }
  }

  CountUpFn(start, end, duration) {
    if (this.state.toRun) {
      return (
        <CountUp
          className="CountUp"
          start={start}
          end={end}
          duration={duration}
          separator=","
          useEasing={false}
        />
      )
    } else {
      return (
        <CountUp
          className="CountUp"
          start={end}
          end={end}
          duration={duration}
          separator=","
          useEasing={false}
        />
      )
    }
  }

  render() {
    return (
      <div className="index-number">
        <div className="index-num">
          {this.CountUpFn(0, 150, 3)}
          <p className="index-num-text">
            <FormattedHTMLMessage id="airPerMonth" />
          </p>
        </div>
        <div className="index-num">
          {this.CountUpFn(0, 100, 3)}
          <p className="index-num-text">
            <FormattedHTMLMessage id="oceanPerMonth" />
          </p>
        </div>
        <div className="index-num">
          {this.CountUpFn(0, 10000, 3)}
          <p className="index-num-text">
            <FormattedHTMLMessage id="expressPerMonth" />
          </p>
        </div>
      </div>
    )
  }
}

class IndexFtz extends Component {
  render() {
    return (
      <div className="index-ftz">
        <div className="index-ftz-title">
          <FormattedHTMLMessage id="indexFtz" />
        </div>
        <div className="index-ftz-content">
          <div className="index-ftz-article">
            <FormattedHTMLMessage id="indexFtzArticle" />
          </div>
          <figure className="index-ftz-img">
            <img src={require("../images/index_ftz.png")} alt="index-ftz-img" />
          </figure>
        </div>
      </div>
    )
  }
}

class IndexContact extends Component {
  componentDidMount() {
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = "//connect.facebook.net/zh_TW/sdk.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")

    if (window.FB) {
      window.FB.XFBML.parse()
    }
  }

  render() {
    let googleMap1, googleMap2, headContactMail, branchContactMail
    if (this.props.locale === "en") {
      headContactMail = "cola@asl-tpe.com"
      branchContactMail = "cola@asl-tpe.com"
      googleMap1 =
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.5630970686143!2d121.55994781554082!3d25.048896943846987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ab957a8195b3%3A0xb8fb2834d82934b5!2sNo.+135%2C+Section+4%2C+Bade+Road%2C+Songshan+District%2C+Taipei+City%2C+105!5e0!3m2!1sen!2stw!4v1557216379093!5m2!1sen!2stw"
      googleMap2 =
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.241508179295!2d121.25236131554139!3d25.0936848420492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34429f83877aeabd%3A0xf4b1cd52e0769f35!2sNo.+5%2C+Hangxiang+Road%2C+Dayuan+District%2C+Taoyuan+City%2C+337!5e0!3m2!1sen!2stw!4v1557215770939!5m2!1sen!2stw"
    } else {
      headContactMail = "cola@asl-tpe.com"
      branchContactMail = "cola@asl-tpe.com"
      googleMap1 =
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7229.126485656764!2d121.562136!3d25.048892!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ab957a58cce9%3A0x2a12016c36bb980f!2zMTA15Y-w5YyX5biC5p2-5bGx5Y2A5YWr5b636Lev5Zub5q61MTM1LTPomZ8!5e0!3m2!1szh-TW!2stw!4v1557216267371!5m2!1szh-TW!2stw"
      googleMap2 =
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7226.483302352979!2d121.25455!3d25.09368!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34429f83877aeabd%3A0xf4b1cd52e0769f35!2zMzM35qGD5ZyS5biC5aSn5ZyS5Y2A6Iiq57-U6LevNeiZnw!5e0!3m2!1szh-TW!2stw!4v1557216303419!5m2!1szh-TW!2stw"
    }
    return (
      <div className="index-contact">
        <div className="index-contact-title">
          <FormattedHTMLMessage id="contactUs" />
        </div>
        <div className="index-contact-content">
          <div className="index-contact-item">
            <div className="index-contact-name">
              <FormattedHTMLMessage id="headOffice" />
            </div>
            <div className="index-contact-map">
              <iframe
                title="googleMap1"
                src={googleMap1}
                width="300"
                height="200"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
              ></iframe>
            </div>
            <div className="index-contact-info">
              <div className="phone">
                <span className="icon icon-phone"></span>
                <FormattedHTMLMessage id="headTel" />
              </div>
              <div className="fax">
                <span className="icon icon-fax"></span>
                <FormattedHTMLMessage id="headFax" />
              </div>
              <div className="add">
                <span className="icon icon-add"></span>
                <FormattedHTMLMessage id="indxeHeadAddress" />
              </div>
              <div className="mail">
                <span className="icon icon-mail"></span>
                <a href={`mailto:${headContactMail}`}>
                  <FormattedHTMLMessage id="headMail" />
                </a>
              </div>
            </div>
          </div>
          <div className="index-contact-item">
            <div className="index-contact-name">
              <FormattedHTMLMessage id="branchOffice" />
            </div>
            <div className="index-contact-map">
              <iframe
                title="googleMap2"
                src={googleMap2}
                width="300"
                height="200"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
              ></iframe>
            </div>
            <div className="index-contact-info">
              <div className="phone">
                <span className="icon icon-phone"></span>
                <FormattedHTMLMessage id="branchTel" />
              </div>
              <div className="fax">
                <span className="icon icon-fax"></span>
                <FormattedHTMLMessage id="branchFax" />
              </div>
              <div className="add">
                <span className="icon icon-add"></span>
                <FormattedHTMLMessage id="indxeBranchAddress" />
              </div>
              <div className="mail">
                <span className="icon icon-mail"></span>
                <a href={`mailto:${branchContactMail}`}>
                  <FormattedHTMLMessage id="branchMail" />
                </a>
              </div>
            </div>
          </div>
          <div className="index-contact-item"></div>
        </div>
      </div>
    )
  }
}

class Main extends Component {
  render() {
    return (
      <Fragment>
        <Slider />
        <IndexAbout />
        <IndexService />
        <IndexNumbers />
        <IndexFtz />
        <IndexContact locale={this.props.locale} />
      </Fragment>
    )
  }
}

export default connect(({ lang: { locale } }) => ({
  locale,
}))(Main)
// export default Main;
