import React , { Component, Fragment} from 'react';
import { connect } from 'react-redux';
import Banner from '../componet/banner';
import RightDots from '../componet/rightDots';
import ArticleTitle from '../componet/articleTitle';
import ArticleContent from '../componet/articleContent';
import History from '../componet/history';
import Team from '../componet/team';


class About extends Component {

    render() {
        const BannerSettings = {
            title : "about",
            items: ["aboutAsl", "history", "team"],
        }

        const ArticleEn = {
            article:"aboutIdeaArticle", 
            img:require('../images/about/Eabout01.jpg'),
            backgroundType: 'right'
        }

        const ArticleTw = {
            article1:{
                page: 'about',
                article:"aboutIdeaArticle", 
                img:require('../images/about/Cabout01.png'),
                backgroundType: '',
                reverse: false
            },
            article2:{
                page: 'about',
                article:"aboutAslArticle", 
                img:require('../images/about/Cabout02.png'),
                backgroundType: 'right',
                reverse: true
            },
        }
    
        if(this.props.locale === 'en') {
            return (
                <Fragment>
                    <Banner {...BannerSettings} />
                    <RightDots {...BannerSettings}/>
                    <div id="aboutAsl" className="article-en hash">
                        <ArticleTitle title="aboutAsl" subtitle="aboutUs"/>
                        <ArticleContent {...ArticleEn}/>
                    </div>
                    <div id="history" className="history hash">
                        <ArticleTitle title="history" subtitle="aboutUs"/>
                        <History locale={this.props.locale}/>
                    </div>
                    <div id="team" className="team hash">
                        <ArticleTitle title="team" subtitle="aboutUs"/>
                        <Team />
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Banner {...BannerSettings} />
                    <RightDots {...BannerSettings}/>
                    <div id="aboutAsl" className="forArticle hash">
                        <div className="article">
                            <ArticleTitle title="aboutIdea" subtitle="aboutUs"/>
                            <ArticleContent {...ArticleTw.article1}/>
                        </div>

                        <div className="article">
                            <ArticleTitle title="aboutAsl" subtitle="aboutUs"/>
                            <ArticleContent {...ArticleTw.article2}/>
                        </div>
                    </div>

                    <div id="history" className="history hash">
                        <ArticleTitle title="history" subtitle="aboutUs"/>
                        <History locale={this.props.locale}/>
                    </div>

                    <div id="team" className="team hash">
                        <ArticleTitle title="team" subtitle="aboutUs"/>
                        <Team />
                    </div>
                </Fragment>
            );    
        }
        
    }
}

export default connect(({lang:{locale}}) => ({
    locale
}))(About);
    

