import React, { Component, Fragment } from "react"
import { FormattedMessage } from "react-intl"

class TeamItem extends Component {
  render() {
    return (
      <figure>
        <img
          className="member-img"
          src={this.props.photo}
          alt="memberPicture"
        ></img>
        <div className="member-info">
          <div className="name">
            {this.props.name}
            <span className="job">
              <FormattedMessage id={this.props.job} />
            </span>
          </div>
          <a href={`mailto:${this.props.mail}`}>
            <span className="icon-mail"></span>
          </a>
        </div>
      </figure>
    )
  }
}

class TeamLIst extends Component {
  render() {
    let members = this.props.members.map((item, index) => {
      return <TeamItem {...item} key={index} />
    })
    return (
      <div className="team-area">
        <div className="area-name">
          <FormattedMessage id={"area" + this.props.area} />
        </div>
        <div className={"team-member"}>{members}</div>
      </div>
    )
  }
}

class Team extends Component {
  render() {
    const Setting = {
      tp: {
        area: "tp",
        members: [
          {
            name: "William",
            job: "ceo",
            mail: "william@asl-tpe.com",
            photo: require("../images/about/team01.jpg"),
          },
          {
            name: "Cola",
            job: "pm",
            mail: "cola@asl-tpe.com",
            photo: require("../images/about/team02.jpg"),
          },
          {
            name: "Jarka",
            job: "os",
            mail: "jaroslawa@asl-tpe.com",
            photo: require("../images/about/team04.jpg"),
          },
        ],
      },
      ty: {
        area: "ty",
        members: [
          {
            name: "Teresa",
            job: "om",
            mail: "teresa@asl-tpe.com",
            photo: require("../images/about/team06.jpg"),
          },
        ],
      },
    }
    return (
      <Fragment>
        <div className="team-content">
          <TeamLIst {...Setting.tp} />
          <TeamLIst {...Setting.ty} />
        </div>
      </Fragment>
    )
  }
}

export default Team
