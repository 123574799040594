import React, { Component} from 'react';
import {FormattedHTMLMessage} from 'react-intl';

class HistoryItem extends Component {

    render () {
        return (
            <div className="item">
                <div className="year"><span>{this.props.year}</span></div>
                <div className="text">
                    <ul className="text-item">
                        <FormattedHTMLMessage id={'history'+this.props.year} />
                    </ul>
                </div>
            </div>
        )   
    }
}
class HistoryList extends Component {
    render() {
        return this.props.history.map((item, index) => (<HistoryItem year={item} key={index}/>))
    }
}

class History extends Component {

    render() {
        const history = {
            tw : [2005, 2006, 2007, 2010, 2011, 2012, 2013, 2014, 2016, 2018],
            en : [2005, 2006, 2009, 2013, 2014, 2016, 2018]
        }
        
        let locale = this.props.locale === 'en' ? 'en': 'tw';
        
        return (
            <div className="history-content">
                <HistoryList history={history[locale]} />
            </div>
        );
    }
}

export default History;
    

