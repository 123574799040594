import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"
import Banner from "../componet/banner"
import ArticleTitle from "../componet/articleTitle"

class ContactItem extends Component {
  render() {
    return (
      <div className="contact-item">
        <div className="info">
          <div className="name">
            <FormattedHTMLMessage id={this.props.company + "Office"} />
          </div>
          <div className="address">
            <FormattedMessage id={this.props.company + "Address"} />
          </div>
          <div className="tel">
            <FormattedMessage id={this.props.company + "Tel"} />
          </div>
          <div className="fax">
            <FormattedMessage id={this.props.company + "Fax"} />
          </div>
          <div className="mail">
            <a href={`mailto:${this.props.contactMail}`}>
              <FormattedHTMLMessage id={this.props.company + "Mail"} />
            </a>
          </div>
          <a
            className="map-link"
            target="_blank"
            href={this.props.mapLink}
            rel="noopener noreferrer"
          >
            Google Map ➞
          </a>
        </div>
        <div className="map">
          <iframe
            title="fb"
            src={this.props.map}
            width="350"
            height="300"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    )
  }
}

class ContactList extends Component {
  render() {
    return Object.keys(this.props.ContactSettings).map((item, index) => {
      return <ContactItem {...this.props.ContactSettings[item]} key={index} />
    })
  }
}

class Contact extends Component {
  render() {
    const BannerSettings = {
      title: "contactUs",
      items: [],
    }

    let headMap, headMapLink, branchMap, branchMapLink, contactMail

    if (this.props.locale === "en") {
      headMap =
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.5630970686143!2d121.55994781554082!3d25.048896943846987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ab957a8195b3%3A0xb8fb2834d82934b5!2sNo.+135%2C+Section+4%2C+Bade+Road%2C+Songshan+District%2C+Taipei+City%2C+105!5e0!3m2!1sen!2stw!4v1557216379093!5m2!1sen!2stw"
      headMapLink = "https://goo.gl/maps/kXvEbjYegXAyukhp6"
      branchMap =
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.241508179295!2d121.25236131554139!3d25.0936848420492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34429f83877aeabd%3A0xf4b1cd52e0769f35!2sNo.+5%2C+Hangxiang+Road%2C+Dayuan+District%2C+Taoyuan+City%2C+337!5e0!3m2!1sen!2stw!4v1557215770939!5m2!1sen!2stw"
      branchMapLink = "https://goo.gl/maps/qpCivXmBMWsPea43A"
      contactMail = "cola@asl-tpe.com"
    } else {
      headMap =
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.562656587954!2d121.55994551500649!3d25.048911883965335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ab957a58cce9%3A0x2a12016c36bb980f!2zMTA15Y-w5YyX5biC5p2-5bGx5Y2A5YWr5b636Lev5Zub5q61MTM1LTPomZ8!5e0!3m2!1szh-TW!2stw!4v1555729313062!5m2!1szh-TW!2stw"
      headMapLink = "https://goo.gl/maps/hTmvMqoGT66Wfowj7"
      branchMap =
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.241508179295!2d121.25236131554139!3d25.0936848420492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34429f83877aeabd%3A0x6f68bbd3ab4e32d8!2zMzM35qGD5ZyS5biC5aSn5ZyS5Y2A6Iiq57-U6LevNeiZnzc!5e0!3m2!1szh-TW!2stw!4v1556429698436!5m2!1szh-TW!2stw"
      branchMapLink = "https://goo.gl/maps/4D56zQ6YMiTch8629"
      contactMail = "cola@asl-tpe.com"
    }

    const ContactSettings = {
      head: {
        company: "head",
        map: headMap,
        mapLink: headMapLink,
        contactMail: contactMail,
      },
      branch: {
        company: "branch",
        map: branchMap,
        mapLink: branchMapLink,
        contactMail: contactMail,
      },
    }

    return (
      <Fragment>
        <Banner {...BannerSettings} />
        <div className="contact">
          <ArticleTitle title="contactUs" subtitle="null" />
          <div className="contact-content">
            <ContactList ContactSettings={ContactSettings} />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default connect(({ lang: { locale } }) => ({
  locale,
}))(Contact)
