import React, { Component } from 'react';
import Logo from '../componet/logo';
import { Link } from "react-router-dom";
import {FormattedMessage} from 'react-intl';

class Footer extends Component {
    renderItem()  {
        const anchorRef = React.createRef()

        return this.props.items.map((item, index) => {
            if(item !== 'home') {
                return (
                    <li className="menu-item" key={index}>
                        <Link to={'/'+item} className="menu-link" innerRef={anchorRef} ><FormattedMessage id={item}/></Link>
                    </li>
                )
            }

            return false;
        })
    }
    render() {
        return (
            <footer>
                <div className="footer-content">
                    <Logo />                        
                    <div className="menu">
                        <ul className="menu-nav">
                            {this.renderItem()}
                        </ul>
                        <div className="copy">© All Star Link Logistics Co., Ltd. All Rights Reserved.</div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
